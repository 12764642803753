import React from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute/protected-route";
import { Helmet } from "react-helmet";
import "./App.css";
import ChangePassword from "./components/change-password.js/change-password";
import ConfirmRegistration from "./components/confirm-registration/confirm-registration";
import Home from "./components/home/home";

const App = () => {


  return (
    <div style={{ zIndex: "4" }}>
      <Helmet>
        <title>Ifjú vadőr</title>
      </Helmet>
      <Router
      >
        <Switch>
          <Route exact path="/home" component={Home} />
          <ProtectedRoute
            path="/changePassword/:id"
            component={ChangePassword}
          />
          <ProtectedRoute
            // path="/confirm-registartion/:id"
            path="/confirm-registartion/:id"
            component={ConfirmRegistration}
          />
          <Redirect from="**" to="/home" />

        </Switch>
      </Router>
    </div>
  );
};

export default App;
