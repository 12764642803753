export const USER = {
  USER_LOADING: "USERS_LOADING",
  USER_LOADED: "USERS_LOADED",
  USER_ERROR: "USERS_ERROR",
  VERIFY_LOADING: "VERIFY_LOADING",
  VERIFY_LOADED: "VERIFY_LOADED",
  VERIFY_ERROR: "VERIFY_ERROR",
  USER_PASSWORD_CHANGE_SAVED: "USER_PASSWORD_CHANGE_SAVED",
  USER_PASSWORD_CHANGE_SAVING: "USER_PASSWORD_CHANGE_SAVING",
  USER_PASSWORD_CHANGE_ERROR: "USER_PASSWORD_CHANGE_ERROR",
};
