import React, { Component } from 'react';
import { connect } from 'react-redux';
import { verifyCodeFunc } from '../../actions/user';

import errorLogo from "../../static/error-logo.png";
import successLogo from "../../static/success-logo.png";
import "./confirm.scss"
const mapDispatchToProps = (dispatch) => {
    return {
        verifyCodeFunc: (code) => dispatch((verifyCodeFunc(code))),
    };
};
class ConnectedConfirmRegistration extends Component {
    state = {
        success: true,
        errorCode: null
    }

    componentDidMount = async () => {
        await this.props.verifyCodeFunc(this.props.match.params.id)
    }

    render() {
        return (
          <div className="base" style={{ width: "100vw", height: "100vh" }}>
            <div className="login-page-containe login-page-container-confirm">
              <div className="confirm-base-div" style={{}}>
                <div className="popup confirm-pop-up">
                  {this.props.verifyStatus === "loaded" && (
                    <div className="confirm-pop-up-first-div">
                      <div className="confirm-pop-up-content-div">
                        <img
                          src={successLogo}
                          alt="success"
                          className="logo-icon confirm-pop-up-response-image"
                        />
                        <div className="lejart-az-ido-2">Megerősítve!</div>
                      </div>
                      <div className="sajnos-tul-sokara-er-2">
                        Sikeresen megerősítetted a felhasználói fiókodat. Jó
                        szórakozást kívánok az Ifjú Vadőr alkalmazás
                        használatához!
                      </div>
                    </div>
                  )}
                  {this.props.verifyStatus &&
                    this.props.verifyStatus === "error" && (
                      <div style={{ width: "100%", height: "100%" }}>
                        {this.props.verifyCode &&
                          this.props.verifyCode === 8001 && (
                            <div className="confirm-pop-up-first-div">
                              <div className="confirm-pop-up-content-div">
                                <img
                                  src={errorLogo}
                                  alt="error"
                                  className="logo-icon confirm-pop-up-response-image"
                                />
                                <div className="lejart-az-ido-2">Megtagadva!</div>
                              </div>
                              <div className="sajnos-tul-sokara-er-2">
                                A regisztrációt megtagadták. Próbáld újra!
                              </div>
                            </div>
                          )}
                        {this.props.verifyCode &&
                          this.props.verifyCode === 8002 && (
                            <div className="confirm-pop-up-first-div">
                              <div className="confirm-pop-up-content-div">
                                <img
                                  src={errorLogo}
                                  alt="error"
                                  className="logo-icon confirm-pop-up-response-image"
                                />
                                <div className="lejart-az-ido-2">
                                  Lejárt az idő!
                                </div>
                              </div>
                              <div className="sajnos-tul-sokara-er-2">
                                Sajnos túl sokára erősítetted meg a fiókot.
                                Kérlek regisztrálj újból az Ifjú Vadőr
                                alkalmazásban!
                              </div>
                            </div>
                          )}
                        {this.props.verifyCode &&
                          this.props.verifyCode === 8003 && (
                            <div className="confirm-pop-up-first-div">
                              <div className="confirm-pop-up-content-div">
                                <img
                                  src={errorLogo}
                                  alt="error"
                                  className="logo-icon confirm-pop-up-response-image"
                                />
                                <div className="lejart-az-ido-2">
                                  Használt kód!
                                </div>
                              </div>
                              <div className="sajnos-tul-sokara-er-2">
                                Sajnos ez a kód már felhasználásra került.
                                Próbáld meg újra!
                              </div>
                            </div>
                          )}
                        {this.props.verifyCode &&
                          this.props.verifyCode === 8004 && (
                            <div className="confirm-pop-up-first-div">
                              <div className="confirm-pop-up-content-div">
                                <img
                                  src={errorLogo}
                                  alt="error"
                                  className="logo-icon confirm-pop-up-response-image"
                                />
                                <div className="lejart-az-ido-2">
                                  Törölt felhasználó!
                                </div>
                              </div>
                              <div className="sajnos-tul-sokara-er-2">
                                A felasználót törölték.
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        );
    }
}
const mapStateToProps = (state) => ({
    verify: state.userReducer.verify,
    verifyStatus: state.userReducer.verifyStatus,
    verifyCode: state.userReducer.verifyCode
});
const ConfirmRegistration = connect(mapStateToProps, mapDispatchToProps)(ConnectedConfirmRegistration);
export default ConfirmRegistration;