import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, } from "react-router-dom";

class ConnectedProtectedRoute extends Component {


  render() {
    return (
      <React.Fragment>

        <Route
          path={this.props.path}
          //component={this.props.component}
          render={(props) =>
            React.createElement(this.props.component, {
              ...props,
              cms: this.props.cms,
            })
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
});

const ProtectedRoute = connect(mapStateToProps, null)(ConnectedProtectedRoute);
export default ProtectedRoute;
