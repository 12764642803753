import { Button, Col, Input, notification, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserByPasswordCode, changeUserPassword } from "../../actions/user";

import errorLogo from "../../static/error-logo.png";
import successLogo from "../../static/success-logo.png";
import "./loading1.scss";
const mapDispatchToProps = (dispatch) => {
  return {
    getUserByPasswordCode: (code) => dispatch(getUserByPasswordCode(code)),
    changeUserPassword: (code) => dispatch(changeUserPassword(code)),
  };
};

const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

class ConnectedChangePassword extends Component {
  state = {
    user: {},
    code: "",
    newPasswordIsStrong: false,
    newPassword: "",
    repeatedNewPassword: "",
    formValid: false,
  };

  componentDidMount = async () => {
    await this.props.getUserByPasswordCode(this.props.match.params.id);
    if (this.props.status === "loaded") {
      this.setState({
        user: this.props.user,
        code: this.props.match.params.id,
      });
    } else if (this.props.status === "error" && this.props.code === 1001) {
      // notification["error"]({
      //   message: "A kód nem megfelelő",
      //   description: "A kapott kód nem szerepel a rendszerben.",
      // });
    } else if (this.props.status === "error" && this.props.code === 1008) {
      // notification["error"]({
      //   message: "Felhasznált kód",
      //   description: "A kapott kódot már felhasználták jelszómodosításra",
      // });
    } else if (this.props.status === "error" && this.props.code === 1007) {
      // notification["error"]({
      //   message: "Lejárt kód",
      //   description: "A kapott kódot lejárt",
      // });
    }
  };

  analyzeNewPassword = (event) => {
    const actualValue = event.target.value;
    this.setState({ newPassword: actualValue });
    if (strongRegex.test(actualValue)) {
      this.setState({ newPasswordIsStrong: true });
    } else {
      this.setState({ newPasswordIsStrong: false });
    }
  };

  handleChangeRepeatedPassword = (event) => {
    const actualValue = event.target.value;
    this.setState({ repeatedNewPassword: actualValue });
  };

  saveEditedUserData = async () => {
    const saveObj = {
      id: this.state.user.id,
      code: this.state.code,
      password: this.state.newPassword,
    };
    await this.props.changeUserPassword(saveObj);
    if (this.props.changeUserPasswordStatus === "saved") {
      notification["success"]({
        message: "Sikeres mentés",
        description: "Sikeres módosítás",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    } else {
      notification["error"]({
        message: "Sikertelen mentés",
        description: "Sikertelen mentés a szerveren",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    }
  };

  formChecker = async () => {
    if (this.state.newPasswordIsStrong !== true) {
      notification["error"]({
        message: "Sikertelen mentés",
        description:
          "A jelszónak tartalmaznia kell kis-  nagybetűt, számot és legalább 8 karakter hosszúnak kell lennie",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    } else if (this.state.newPassword !== this.state.repeatedNewPassword) {
      notification["error"]({
        message: "Sikertelen mentés",
        description: "A újonan megadott jelszavak nem egyeznek",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    } else {
      await this.saveEditedUserData();
    }
  };

  render() {
    return (
      <div
        className="base"
        style={{
          width: "100vw",
          height: "100vh",
          textAlign: " -webkit-center",
        }}
      >
        <div
          className="login-page-container"
          style={{
            minHeight: "100%",
            minWidth: "100%",
            display: "flex",
            position: "fixed",
            justifyContent: "center",
          }}
        >
          <div>
            <div
              className="group-16"
              style={{
                backgroundImage: `url("/background_picture.png")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                zIndex: 99,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        {this.state.user.email &&
          this.props.changeUserPasswordStatus !== "saved" && (
            <Row
              gutter={[0, 0]}
              align="middle"
              justify="center"
              style={{ maxWidth: "min-content" }}
            >
              {/* <div style={{ width: "460px" }}> */}

              <Row
                gutter={[24, 24]}
                align="middle"
                justify="center"
                style={{ margin: "0px" }}
              >
                <Col className="kerlek-add-meg-az-u" span={24}>
                  Kérlek, add meg az új jelszót!
                </Col>

                <Col span={12} style={{ display: "contents" }}>
                  <Input.Password
                    className="uj-jelszo"
                    placeholder="Új jelszó"
                    onChange={this.analyzeNewPassword}
                  />
                </Col>
                <Col span={12} style={{ display: "contents" }}>
                  <Input.Password
                    className="uj-jelszo"
                    placeholder="Új jelszó újra"
                    onChange={this.handleChangeRepeatedPassword}
                  ></Input.Password>
                </Col>
              </Row>

              <Row gutter={[24, 24]} align="middle" justify="center">
                <Col className="minimum-8-karakter">
                  Minimum 8 karakter, kis- és nagybetű, szám
                </Col>
              </Row>
              <Row gutter={[24, 24]} align="middle" justify="space-between">
                <Col span={12}>
                  <Button className="megsem">Mégsem</Button>
                </Col>
                <Col span={12}>
                  <Button
                    className="buttons-medium-button-with-ico"
                    type="primary"
                    onClick={this.formChecker}
                  >
                    Jóváhagyás
                  </Button>
                </Col>
              </Row>
              {/* </div> */}
            </Row>
            )}
          </div>
        {this.props.changeUserPasswordStatus === "saved" && (
          <div className="confirm-base-div-change-password" style={{}}>
            <div className="popup confirm-pop-up">
              <Row align="middle" justify="center" style={{ height: "100vh" }}>
                <div className="confirm-pop-up-first-div">
                  <div className="confirm-pop-up-content-div-change">
                    <img
                      src={successLogo}
                      alt="success"
                      className="logo-icon confirm-pop-up-response-image"
                    />
                    <div className="lejart-az-ido-2-confirm">
                      Sikeres mentés!
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        )}
        {this.props.status &&
          this.props.status === "error" &&
          this.props.code === 1001 && (
            <div className="confirm-base-div-change-password" style={{}}>
              <div className="popup confirm-pop-up">
                <Row
                  align="middle"
                  justify="center"
                  style={{ height: "100vh" }}
                >
                  <div className="confirm-pop-up-first-div">
                    <div className="confirm-pop-up-content-div-change">
                      <img
                        src={errorLogo}
                        alt="error"
                        className="logo-icon confirm-pop-up-response-image"
                      />
                      <div className="lejart-az-ido-2-confirm">
                        A kapott kód nem szerepel a rendszerben.
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          )}
        {this.props.status &&
          this.props.status === "error" &&
          this.props.code === 1008 && (
            <div className="confirm-base-div-change-password" style={{}}>
              <div className="popup confirm-pop-up">
                <Row
                  align="middle"
                  justify="center"
                  style={{ height: "100vh" }}
                >
                  <div className="confirm-pop-up-first-div">
                    <div className="confirm-pop-up-content-div-change">
                      <img
                        src={errorLogo}
                        alt="error"
                        className="logo-icon confirm-pop-up-response-image"
                      />
                      <div className="lejart-az-ido-2-confirm">
                        A kapott kódot már felhasználták.
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          )}
        {this.props.status &&
          this.props.status === "error" &&
          this.props.code === 1007 && (
            <div className="confirm-base-div-change-password" style={{}}>
              <div className="popup confirm-pop-up">
                <Row
                  align="middle"
                  justify="center"
                  style={{ height: "100vh" }}
                >
                  <div className="confirm-pop-up-first-div">
                    <div className="confirm-pop-up-content-div-change">
                      <img
                        src={errorLogo}
                        alt="error"
                        className="logo-icon confirm-pop-up-response-image"
                      />
                      <div className="lejart-az-ido-2-confirm">
                        A kapott kód már lejárt.
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  status: state.userReducer.status,
  code: state.userReducer.code,
  changeUserPasswordStatus: state.userReducer.changeUserPasswordStatus,
});
const ChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedChangePassword);
export default ChangePassword;
