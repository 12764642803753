import { USER } from "../constants/action-types";

const initialState = {
    user: {},
    changeUserPasswordStatus: null,
    status: null,
    message: {},
    code: null,
    verifyStatus: null,
    verifyCode: null,
    verify: false

};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER.VERIFY_LOADING:
            return Object.assign({}, state, {
                verify: false,
                verifyStatus: "loading",
                verifyCode: null
            });
        case USER.VERIFY_LOADED:
            return Object.assign({}, state, {
                verify: true,
                verifyStatus: "loaded",
                verifyCode: null
            });
        case USER.VERIFY_ERROR:
            return Object.assign({}, state, {
                verify: false,
                verifyStatus: "error",
                verifyCode: action.payload.code
            });
        case USER.USER_LOADING:
            return Object.assign({}, state, {
                user: {},
                message: {},
                status: "loading",
                code: null
            });
        case USER.USER_LOADED:
            return Object.assign({}, state, {
                user: action.payload.user,
                status: "loaded",
                message: {},
                code: null
            });
        case USER.USER_ERROR:
            return Object.assign({}, state, {
                user: {},
                status: "error",
                message: action.payload.message,
                code: action.payload.code.error.code
            });
        case USER.USER_PASSWORD_CHANGE_SAVING:
            return Object.assign({}, state, {
                message: {},
                changeUserPasswordStatus: "saving",
                code: null
            });
        case USER.USER_PASSWORD_CHANGE_SAVED:
            return Object.assign({}, state, {
                changeUserPasswordStatus: "saved",
                message: {},
                code: null
            });
        case USER.USER_PASSWORD_CHANGE_ERROR:
            return Object.assign({}, state, {
                changeUserPasswordStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        default:
            return state;
    }
};

export default userReducer;
