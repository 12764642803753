import axios from "axios";
import { USER } from "../constants/action-types";


export const getUserByPasswordCode = (code) => {
    return async (dispatch) => {
        dispatch({ type: USER.USER_LOADING });
        await axios
            .get(process.env.REACT_APP_BASE_URL + "user/get-user-by-password-code/" + code, {
            })
            .then((response) => {
                dispatch({
                    type: USER.USER_LOADED,
                    payload: response.data

                });
            })
            .catch((err) => {
                dispatch({
                    type: USER.USER_ERROR,
                    payload: { message: err.response.data.message, code: err.response.data },
                });
            });
    };
};
export const verifyCodeFunc = (code) => {
    return async (dispatch) => {
        dispatch({ type: USER.VERIFY_LOADING });
        await axios
            .get(process.env.REACT_APP_BASE_URL + "auth/confirm/" + code, {
            })
            .then((response) => {
                dispatch({
                    type: USER.VERIFY_LOADED,
                    payload: response.data

                });
            })
            .catch((err) => {
                dispatch({
                    type: USER.VERIFY_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const changeUserPassword = (obj) => {
    return async (dispatch) => {
        dispatch({ type: USER.USER_PASSWORD_CHANGE_SAVING });
        await axios
            .put(process.env.REACT_APP_BASE_URL + "user/change-password", obj)
            .then((response) => {
                dispatch({
                    type: USER.USER_PASSWORD_CHANGE_SAVED,
                    payload: response.data
                });
            })
            .catch((err) => {
                dispatch({
                    type: USER.USER_PASSWORD_CHANGE_ERROR,
                    payload: { message: err.response.data.message, code: err.response.data.code },
                });
            });
    };
};