import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./home.scss"
const mapDispatchToProps = (dispatch) => {
    return {};
};
class ConnectedHome extends Component {
    render() {
        return <div className="base" style={{ width: "100vw", height: "100vh", }}>
            <div className="login-page-container"
                style={{
                    minHeight: "100%",
                    minWidth: "100%",
                    display: "flex",
                    position: "fixed",
                    justifyContent: "center"
                }}>
                <div>
                    <div
                        className="group-16"
                        style={{
                            backgroundImage: `url("/background_picture.png")`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            zIndex: 99,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                        }}>
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                <h1 style={{ backgroundRepeat: "no-repeat", fontFamily: "Metropolis", color: "#795A21", fontSize: 100, zIndex: 100 }}>Ifjú vadőr</h1>
            </div>
        </div>;
    }
}
const mapStateToProps = (state) => ({});
const Home = connect(mapStateToProps, mapDispatchToProps)(ConnectedHome);
export default Home;